import { useEffect, useState } from 'react';

import { WithLayout } from 'common/hocs/WithLayout';
import { useAppDispatch, useAppSelector, useDebounce } from 'common/hooks';
import { LoadingStatusEnum, SelectedViewEnum } from 'common/types';
import { ProductsPerPage, SkeletonProductCard } from 'elements';
import { ProductCard } from 'modules';
import {
  clearFavoritesSearchValue,
  setFavoritesSearchValue,
} from 'store/favoritesSearchSlice';
import { productsPerPageSelector, setProductsPerPage } from 'store/reducers/productSlice';
import {
  favoriteProductsSelector,
  getFavoritesProductsService,
  userLoadingSelector,
} from 'store/reducers/userSlice';
import { ButtonQuestion, LoaderLinear, Title } from 'ui-kit';
import { Pagination } from 'ui-kit/Pagination/Pagination';
import { SearchComponent } from 'ui-kit/SearchComponent/SearchComponent';

import style from './SellerFavoritesList.module.scss';

const MIN_PRODUCT_PER_PAGE = 20;

export const SellerFavoritesList = WithLayout((): JSX.Element => {
  const dispatch = useAppDispatch();
  const { products: favoriteProducts, total_count } = useAppSelector(
    favoriteProductsSelector,
  );
  const productsPerPage = useAppSelector(productsPerPageSelector);
  const search = useAppSelector(state => state.favorites.search);
  const isLoading =
    useAppSelector(userLoadingSelector).favoritesProductsLoading ===
    LoadingStatusEnum.Loading;
  const debouncedSearch = useDebounce(search);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(total_count / productsPerPage);
  const isMoreThanProductPerPage = total_count >= MIN_PRODUCT_PER_PAGE;

  const handleSearch = (value: string): void => {
    dispatch(setFavoritesSearchValue(value));
  };

  const handleRemoveValue = (): void => {
    dispatch(clearFavoritesSearchValue());
  };

  const onSearchFavorites = (): void => {
    dispatch(
      getFavoritesProductsService({
        offset: (currentPage - 1) * productsPerPage,
        limit: productsPerPage,
        query: search,
      }),
    );
  };

  const handleChangeSelect = (value: number): void => {
    dispatch(setProductsPerPage(value));
  };

  useEffect(() => {
    onSearchFavorites();
  }, [productsPerPage, currentPage, debouncedSearch]);

  useEffect(() => {
    return () => {
      handleRemoveValue();
    };
  }, []);

  const productSkeleton = Array.from({ length: productsPerPage }).map((el, i) => (
    <SkeletonProductCard key={i} selectedView={SelectedViewEnum.GRID} />
  ));

  const favoriteProductsView = favoriteProducts.map(product => (
    <ProductCard product={product} key={product.id} isFavorite />
  ));

  const paginationComponent = (
    <Pagination
      totalPages={totalPages}
      currentPage={currentPage}
      onPageChanged={setCurrentPage}
      disabled={isLoading}
    />
  );

  return (
    <div className={style.favorites_page}>
      {isLoading && <LoaderLinear />}
      <div className={style.container}>
        <div className={style.top}>
          <Title as="h3">Favorites list</Title>
          <SearchComponent
            className={style.search}
            placeholder="Search within my favorites"
            value={search}
            onChange={handleSearch}
            handleRemoveValue={handleRemoveValue}
            handleKeyDown={onSearchFavorites}
          />
        </div>
        {isMoreThanProductPerPage && (
          <div className={style.pagination}>{paginationComponent}</div>
        )}
        <div className={style.empty_products_list}>
          {!favoriteProductsView.length && !isLoading && <h2>No data with this terms</h2>}
        </div>
        <div className={style.main}>
          {isLoading ? productSkeleton : favoriteProductsView}
        </div>
        <div className={style.control_panel}>
          {isMoreThanProductPerPage && (
            <ProductsPerPage disabled={isLoading} onChange={handleChangeSelect} />
          )}
          {isMoreThanProductPerPage && paginationComponent}
        </div>
        <div className={style.bottom}>
          <ButtonQuestion />
        </div>
      </div>
    </div>
  );
});
