import { FC, useEffect, useRef, useState } from 'react';

import cn from 'classnames';

import { ILocationAndCurrencySelection } from './LocationAndCurrencySelection.props';

import { ArrowIcon } from 'assets/icons';
import { SELECTED_LABEL } from 'common/constants';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { useOnClickOutside } from 'common/hooks/useOnClickOutside';
import { generateSvgPath } from 'common/utils';
import { countriesSelector, getCountries } from 'store/reducers/commonSlice';
import { ISelectOption, Select } from 'ui-kit';

import style from './LocationAndCurrencySelection.module.scss';

const CURRENCY_DATA: ISelectOption[] = [
  { label: { text: 'Russian / RUB' }, value: 'ru' },
  { label: { text: 'English / USD' }, value: 'usd' },
];

export const LocationAndCurrencySelection: FC<ILocationAndCurrencySelection> = ({
  className,
  dropOnUp = false,
  isMobileView,
  wrapperClassName,
}) => {
  const dispatch = useAppDispatch();
  const countries = useAppSelector(countriesSelector);
  const isMounted = useRef(false);
  const [countriesData, setCountriesData] = useState<ISelectOption[]>([]);
  const [selectedLabel, setSelectedLabel] = useState<ISelectOption>();
  const [isOpenOnMobile, setOpenOnMobile] = useState(false);

  const containerClasses = cn(style.container, className, {
    [style.mobile]: isMobileView,
    [style.show]: isMobileView && isOpenOnMobile,
  });

  const handleToggleOpenOnMobile = (): void => {
    setOpenOnMobile(prev => !prev);
  };

  const refObj = useOnClickOutside(setOpenOnMobile);

  const handleSelect = (value: ISelectOption): void => {
    dispatch(getCountries());
    localStorage.setItem(SELECTED_LABEL, JSON.stringify(value));
    setSelectedLabel(value);
  };

  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);

  useEffect(() => {
    const serializedValue = localStorage.getItem(SELECTED_LABEL);

    if (serializedValue) {
      setSelectedLabel(JSON.parse(serializedValue));
    } else {
      setSelectedLabel(countriesData[0]);
    }
  }, [countriesData]);

  useEffect(() => {
    if (countries.length && !isMounted.current) {
      isMounted.current = true;

      setCountriesData(
        countries.map(el => ({
          label: { text: el.country, image_src: el.flag },
          value: el.id,
        })),
      );
    }
  }, [countries]);

  return (
    <div ref={refObj} className={cn(style.wrapper, wrapperClassName)}>
      {isMobileView && (
        <div onClickCapture={handleToggleOpenOnMobile} className={style.mobile_header}>
          <div className={style.currency}>
            {CURRENCY_DATA[0].label.text.substring(
              CURRENCY_DATA[0].label.text.length - 3,
            )}
          </div>

          {selectedLabel && (
            <img
              width="20px"
              src={generateSvgPath(String(selectedLabel.label.image_src))}
              alt="flag"
            />
          )}

          <ArrowIcon className={cn({ [style.arrow_up]: isOpenOnMobile })} width="14" />
        </div>
      )}

      <div className={containerClasses}>
        <Select
          dropOnUp={dropOnUp}
          options={CURRENCY_DATA}
          width="172px"
          header
          className={style.select}
        />

        <div className={style.select_box}>
          <span className={style.select_title}>Ship to</span>
          {countriesData.length && (
            <Select
              onChange={handleSelect}
              dropOnUp={dropOnUp}
              options={countriesData}
              header
              width="180px"
              className={style.select}
              isCountrySelect
            />
          )}
        </div>
      </div>
    </div>
  );
};
