import {
  ChangeEvent,
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  KeyboardEvent,
} from 'react';

import cn from 'classnames';

import { CrossRedIcon } from 'assets/icons';
import { ButtonIcon } from 'ui-kit/buttons/ButtonIcon/ButtonIcon';

import styles from './SearchComponent.module.scss';

interface ISearch
  extends Omit<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'type' | 'value' | 'onChange'
  > {
  isPhotoSearch?: boolean;
  handleSearch?: () => void;
  value: string;
  onChange: (value: string) => void;
  handleKeyDown?: () => void;
  handleRemoveValue?: () => void;
}

export const SearchComponent = forwardRef<HTMLInputElement, ISearch>(
  (props, ref): JSX.Element => {
    const {
      className,
      isPhotoSearch = false,
      value,
      onChange,
      handleKeyDown,
      handleRemoveValue,
      ...restProps
    } = props;

    const handleChangeValue = (e: ChangeEvent<HTMLInputElement>): void => {
      onChange(e.target.value);
    };

    const onEnterKewDown = (e: KeyboardEvent): void => {
      if (e.key === 'Enter') {
        handleKeyDown?.();
      }
    };

    return (
      <div className={cn(styles.wrapper, className)}>
        <input
          className={styles.input}
          ref={ref}
          value={value}
          onChange={handleChangeValue}
          onKeyDown={onEnterKewDown}
          type="text"
          {...restProps}
        />

        {isPhotoSearch && (
          <label className={styles.button}>
            <input className={styles.photo_search} type="file" />
          </label>
        )}

        {!!value.length && (
          <ButtonIcon className={styles.button_cross} onClick={handleRemoveValue}>
            <CrossRedIcon className={styles.cross_icon} />
          </ButtonIcon>
        )}
      </div>
    );
  },
);
