import { IProductBundle } from 'store/reducers/productSlice/types';

export const bundleSize: IProductBundle = {
  id: 816,
  created_at: '2024-04-17T15:21:34.210542+00:00',
  updated_at: '2024-04-17T15:21:34.210542',
  prices: [],
  product: 'string',
  variation_values: [
    {
      id: 1269,
      created_at: '2024-04-17T15:21:34.210542+00:00',
      updated_at: '2024-04-17T15:21:34.210542+00:00',
      variation_value_to_product_id: 6102,
      bundle_id: 816,
      amount: 2,
      bundle: 'string',
      product_variation: {
        id: 6282,
        created_at: '2024-04-17T15:21:34.210542+00:00',
        updated_at: '2024-04-17T15:21:34.210542+00:00',
        variation_value_id: 59,
        product_id: 842,
        variation: {
          id: 59,
          created_at: '2024-04-17T15:21:33.251813+00:00',
          updated_at: '2024-04-17T15:21:33.251813+00:00',
          value: '6XL',
          variation_type_id: 3,
          type: {
            id: 3,
            created_at: '2024-04-17T15:21:33.187184+00:00',
            updated_at: '2024-04-17T15:21:33.187184+00:00',
            name: 'Size-Men-Universal',
          },
          variation: 56,
        },
        product: 'string',
        prices: [
          {
            id: 6282,
            created_at: '2024-04-17T15:21:34.210542+00:00',
            updated_at: '2024-04-17T15:21:34.210542+00:00',
            variation_value_to_product_id: 6282,
            value: 4.264,
            multiplier: 0.56098,
            discount: 0.62,
            start_date: '2023-11-22T00:00:00',
            end_date: '2024-02-05T00:00:00',
            min_quantity: 19,
            product_variation_value: 'xxl',
          },
        ],
      },
    },
    {
      id: 1270,
      created_at: '2024-04-17T15:21:34.210542+00:00',
      updated_at: '2024-04-17T15:21:34.210542+00:00',
      variation_value_to_product_id: 6102,
      bundle_id: 816,
      amount: 7,
      bundle: 'string',
      product_variation: {
        id: 6282,
        created_at: '2024-04-17T15:21:34.210542+00:00',
        updated_at: '2024-04-17T15:21:34.210542+00:00',
        variation_value_id: 59,
        product_id: 842,
        variation: {
          id: 59,
          created_at: '2024-04-17T15:21:33.251813+00:00',
          updated_at: '2024-04-17T15:21:33.251813+00:00',
          value: '7XL',
          variation_type_id: 3,
          type: {
            id: 3,
            created_at: '2024-04-17T15:21:33.187184+00:00',
            updated_at: '2024-04-17T15:21:33.187184+00:00',
            name: 'Size-Men-Universal',
          },
          variation: 56,
        },
        product: 'string',
        prices: [
          {
            id: 6282,
            created_at: '2024-04-17T15:21:34.210542+00:00',
            updated_at: '2024-04-17T15:21:34.210542+00:00',
            variation_value_to_product_id: 6282,
            value: 4.264,
            multiplier: 0.56098,
            discount: 0.62,
            start_date: '2023-11-22T00:00:00',
            end_date: '2024-02-05T00:00:00',
            min_quantity: 19,
            product_variation_value: 'xxl',
          },
        ],
      },
    },
    {
      id: 1271,
      created_at: '2024-04-17T15:21:34.210542+00:00',
      updated_at: '2024-04-17T15:21:34.210542+00:00',
      variation_value_to_product_id: 6102,
      bundle_id: 816,
      amount: 17,
      bundle: 'string',
      product_variation: {
        id: 6282,
        created_at: '2024-04-17T15:21:34.210542+00:00',
        updated_at: '2024-04-17T15:21:34.210542+00:00',
        variation_value_id: 59,
        product_id: 842,
        variation: {
          id: 59,
          created_at: '2024-04-17T15:21:33.251813+00:00',
          updated_at: '2024-04-17T15:21:33.251813+00:00',
          value: '8XL',
          variation_type_id: 3,
          type: {
            id: 3,
            created_at: '2024-04-17T15:21:33.187184+00:00',
            updated_at: '2024-04-17T15:21:33.187184+00:00',
            name: 'Size-Men-Universal',
          },
          variation: 56,
        },
        product: 'string',
        prices: [
          {
            id: 6282,
            created_at: '2024-04-17T15:21:34.210542+00:00',
            updated_at: '2024-04-17T15:21:34.210542+00:00',
            variation_value_to_product_id: 6282,
            value: 4.264,
            multiplier: 0.56098,
            discount: 0.62,
            start_date: '2023-11-22T00:00:00',
            end_date: '2024-02-05T00:00:00',
            min_quantity: 19,
            product_variation_value: 'xxl',
          },
        ],
      },
    },
  ],
  pickable_variations: [
    {
      id: 6104,
      created_at: '2024-04-17T15:21:34.210542+00:00',
      updated_at: '2024-04-17T15:21:34.210542+00:00',
      variation_value_id: 58,
      product_id: 816,
      variation: {
        id: 58,
        created_at: '2024-04-17T15:21:33.251813+00:00',
        updated_at: '2024-04-17T15:21:33.251813+00:00',
        value: '5XL',
        variation_type_id: 3,
        type: {
          id: 3,
          created_at: '2024-04-17T15:21:33.187184+00:00',
          updated_at: '2024-04-17T15:21:33.187184+00:00',
          name: 'Size-Men-Universal',
        },
        variation: 55,
      },
      product: 'string',
      prices: [],
    },
    {
      id: 6103,
      created_at: '2024-04-17T15:21:34.210542+00:00',
      updated_at: '2024-04-17T15:21:34.210542+00:00',
      variation_value_id: 59,
      product_id: 817,
      variation: {
        id: 59,
        created_at: '2024-04-17T15:21:33.251813+00:00',
        updated_at: '2024-04-17T15:21:33.251813+00:00',
        value: 'X',
        variation_type_id: 4,
        type: {
          id: 4,
          created_at: '2024-04-17T15:21:33.187184+00:00',
          updated_at: '2024-04-17T15:21:33.187184+00:00',
          name: 'Size-Men-Universal',
        },
        variation: 56,
      },
      product: 'string',
      prices: [],
    },
  ],
};
export const bundleColor: IProductBundle = {
  id: 816,
  created_at: '2024-04-17T15:21:34.210542+00:00',
  updated_at: '2024-04-17T15:21:34.210542',
  prices: [],
  product: 'string',
  variation_values: [
    {
      id: 1269,
      created_at: '2024-04-17T15:21:34.210542+00:00',
      updated_at: '2024-04-17T15:21:34.210542+00:00',
      variation_value_to_product_id: 6102,
      bundle_id: 816,
      amount: 2,
      bundle: 'string',
      // @ts-ignore
      product_variation: {
        id: 6282,
        created_at: '2024-04-17T15:21:34.210542+00:00',
        updated_at: '2024-04-17T15:21:34.210542+00:00',
        variation_value_id: 59,
        product_id: 842,
        variation: {
          id: 59,
          created_at: '2024-04-17T15:21:33.251813+00:00',
          updated_at: '2024-04-17T15:21:33.251813+00:00',
          value: 'Beige',
          variation_type_id: 3,
          image_url:
            'https://abra-static-storage-develop.s3.eu-central-1.amazonaws.com/colors/BEIGE.png',
          type: {
            id: 3,
            created_at: '2024-04-17T15:21:33.187184+00:00',
            updated_at: '2024-04-17T15:21:33.187184+00:00',
            name: 'Size-Men-Universal',
          },
          variation: 56,
        },
        product: 'string',
        prices: [
          {
            id: 6282,
            created_at: '2024-04-17T15:21:34.210542+00:00',
            updated_at: '2024-04-17T15:21:34.210542+00:00',
            variation_value_to_product_id: 6282,
            value: 4.264,
            multiplier: 0.56098,
            discount: 0.62,
            start_date: '2023-11-22T00:00:00',
            end_date: '2024-02-05T00:00:00',
            min_quantity: 19,
            product_variation_value: 'xxl',
          },
        ],
      },
    },
  ],
  pickable_variations: [
    {
      id: 6104,
      created_at: '2024-04-17T15:21:34.210542+00:00',
      updated_at: '2024-04-17T15:21:34.210542+00:00',
      variation_value_id: 58,
      product_id: 816,
      variation: {
        id: 58,
        created_at: '2024-04-17T15:21:33.251813+00:00',
        updated_at: '2024-04-17T15:21:33.251813+00:00',
        value: 'Indigo',
        variation_type_id: 3,
        image_url:
          'https://abra-static-storage-develop.s3.eu-central-1.amazonaws.com/colors/INDIGO.png',

        type: {
          id: 3,
          created_at: '2024-04-17T15:21:33.187184+00:00',
          updated_at: '2024-04-17T15:21:33.187184+00:00',
          name: 'Size-Men-Universal',
        },
        variation: 55,
      },
      product: 'string',
      prices: [],
    },
    {
      id: 6103,
      created_at: '2024-04-17T15:21:34.210542+00:00',
      updated_at: '2024-04-17T15:21:34.210542+00:00',
      variation_value_id: 59,
      product_id: 817,
      variation: {
        id: 59,
        created_at: '2024-04-17T15:21:33.251813+00:00',
        updated_at: '2024-04-17T15:21:33.251813+00:00',
        value: 'Green',
        variation_type_id: 4,
        image_url:
          'https://abra-static-storage-develop.s3.eu-central-1.amazonaws.com/colors/GREEN.png',
        type: {
          id: 4,
          created_at: '2024-04-17T15:21:33.187184+00:00',
          updated_at: '2024-04-17T15:21:33.187184+00:00',
          name: 'Size-Men-Universal',
        },
        variation: 56,
      },
      product: 'string',
      prices: [],
    },
  ],
};
