import React, { FC } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { DeleteTrashCanIcon } from 'assets/icons';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { LoadingStatusEnum } from 'common/types';
import { PhoneNumberInput } from 'elements';
import { ISellerAddressData } from 'services/seller/seller.serviceTypes';
import { countriesSelector } from 'store/reducers/commonSlice';
import {
  sellerLoadingSelector,
  deleteSellerAddress,
  ISellerAddress,
} from 'store/reducers/seller/profile';
import { Button, Checkbox, Input, Label, Paragraph, Select, Title } from 'ui-kit';

import style from './AddressesChangeForm.module.scss';

interface IAddressesChangeForm {
  onSubmit: (data: ISellerAddress) => Promise<void>;
  isEditForm?: boolean;
  closeModal?: (modal: boolean) => void;
  address?: ISellerAddressData;
  isAddressFormDisable?: boolean;
}

export const AddressesChangeForm: FC<IAddressesChangeForm> = ({
  isEditForm,
  address,
  isAddressFormDisable,
  onSubmit,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const countries = useAppSelector(countriesSelector);
  const isLoading =
    useAppSelector(sellerLoadingSelector).addressesLoading === LoadingStatusEnum.Loading;

  const {
    register,
    watch,
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useFormContext<ISellerAddress>();

  const listCountry = countries.map(el => ({
    value: el.id,
    label: { text: el.country },
  }));

  const removeAddress = async (): Promise<void> => {
    if (address) {
      dispatch(deleteSellerAddress(address.id));
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={style.address_form}>
      <fieldset disabled={isLoading}>
        <div className={style.address_form_header}>
          <Title as="h2" size="m">
            {isEditForm ? 'Edit Address' : 'Add Address'}
          </Title>
          <div className={style.address_form_checkbox}>
            {isEditForm && (
              <button
                className={style.delete_address}
                onClick={removeAddress}
                type="button"
              >
                <DeleteTrashCanIcon />
                <span>Remove Address</span>
              </button>
            )}
            <Controller
              control={control}
              name="isMain"
              defaultValue={isEditForm ? address?.is_main : undefined}
              render={({ field }) => (
                <Checkbox
                  checked={field.value || false}
                  className={style.checkbox}
                  variant="default"
                  label="Main Address"
                  onChange={event => field.onChange(event.currentTarget.checked)}
                />
              )}
            />
          </div>
        </div>
        <div className={style.address_form_block}>
          <Paragraph size="m" weight="medium" className={style.address_form_block_title}>
            Recipient Info
          </Paragraph>
          <div className={style.address_form_block_row}>
            <div className={style.address_form_item}>
              <Label label="First name">
                <Input
                  {...register('firstName')}
                  classNameWrapper={style.address_form_input}
                  placeholder="Recipient’s first name"
                  defaultValue={isEditForm ? address?.first_name : ''}
                  error={errors?.firstName?.message}
                />
              </Label>
            </div>
            <div className={style.address_form_item}>
              <Label label="Last name">
                <Input
                  {...register('lastName')}
                  classNameWrapper={style.address_form_input}
                  placeholder="Recipient’s last name"
                  defaultValue={isEditForm ? address?.last_name : ''}
                  error={errors.lastName?.message}
                />
              </Label>
            </div>
          </div>
          <div className={style.address_popup_phone}>
            <PhoneNumberInput
              label="Personal phone number"
              countryShort={address?.phone.country.country_short}
              disabled={isLoading}
            />
          </div>
        </div>
        <div className={style.address_form_block}>
          <div className={style.address_popup_block_title}>Where to deliver</div>
          <div className={style.address_form_block_row}>
            <Controller
              control={control}
              name="country"
              render={({ field }) => (
                <Label label="Country">
                  <Select
                    {...field}
                    options={listCountry}
                    placeholder="Select a country"
                    className={style.select}
                    error={errors.country?.message}
                    defaultValue={watch('country')}
                    onChange={value => {
                      field.onChange(Number(value.value));
                    }}
                    disabled={isLoading}
                  />
                </Label>
              )}
            />

            <div className={style.address_form_item}>
              <Label label="State / Province (optional)">
                <Input
                  {...register('area')}
                  classNameWrapper={style.address_form_input}
                  placeholder="Enter a state or province name"
                  defaultValue={isEditForm ? address?.area : ''}
                  error={errors.area?.message}
                />
              </Label>
            </div>
          </div>
          <div className={style.address_form_block_row}>
            <div className={style.address_form_item}>
              <Label label="City / Town">
                <Input
                  {...register('city')}
                  classNameWrapper={style.address_form_input}
                  placeholder="Enter a city or town name"
                  defaultValue={isEditForm ? address?.city : ''}
                  error={errors.city?.message}
                />
              </Label>
            </div>
            <div className={style.address_form_block_row}>
              <Label label="Building (optional)">
                <Input
                  {...register('building')}
                  classNameWrapper={style.address_form_input}
                  placeholder="Enter a building number"
                  defaultValue={isEditForm ? address?.building : ''}
                  error={errors.building?.message}
                />
              </Label>
            </div>
          </div>
          <div className={style.address_form_item}>
            <Label label="Street address">
              <Input
                {...register('street')}
                classNameWrapper={style.address_form_input}
                placeholder="Enter a street name and number"
                defaultValue={isEditForm ? address?.street : ''}
                error={errors.street?.message}
              />
            </Label>
          </div>
          <div className={style.address_form_block_row}>
            <div className={style.address_form_item}>
              <Label label="Apt, suite, office (optional)">
                <Input
                  {...register('apartment')}
                  classNameWrapper={style.address_form_input}
                  placeholder="Enter a number or a letter"
                  defaultValue={isEditForm ? address?.apartment : ''}
                  error={errors.apartment?.message}
                />
              </Label>
            </div>
            <div className={style.address_form_item}>
              <Label label="Zip Code">
                <Input
                  {...register('postalCode')}
                  classNameWrapper={style.address_form_input}
                  placeholder="Enter a postal code"
                  defaultValue={isEditForm ? address?.postal_code : ''}
                  error={errors.postalCode?.message}
                />
              </Label>
            </div>
          </div>
          <Button
            className={style.address_form_button}
            type="submit"
            label="Confirm"
            disabled={!isValid || (isEditForm && isAddressFormDisable)}
          />
        </div>
      </fieldset>
    </form>
  );
};
