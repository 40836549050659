import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  search: '',
};

export const favoritesSearchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setFavoritesSearchValue: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    clearFavoritesSearchValue: state => {
      state.search = '';
    },
  },
});

export const { setFavoritesSearchValue, clearFavoritesSearchValue } =
  favoritesSearchSlice.actions;
export const favoritesReducer = favoritesSearchSlice.reducer;
