export enum CountriesEnum {
  AZERBAIJAN = 1,
  BELARUS = 2,
  KAZAKHSTAN = 3,
  KYRGYZSTAN = 4,
  RUSSIAN = 5,
  TAJIKISTAN = 6,
  TURKEY = 7,
  UKRAINE = 8,
  UZBEKISTAN = 9,
}
